import React from 'react';
import Scores from '../Scores/Scores';
import Utilities from '../classes/Utilities';
import './UpcomingGames.css';
import { useTranslation } from "react-i18next";
import { MultiSelect } from "react-multi-select-component";
import SettingStorage from '../classes/SettingsStorage';

const UpcomingGames = (props) => {        
    const { t } = useTranslation();    
    let teamFilter = SettingStorage.getStorageValue("nhlcountries_teamFilter", "[]");
    const [teamFilterValue, setTeamFilter] = React.useState(JSON.parse(teamFilter));
    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        return `${Utilities.getWeekday(t, date)} ${date.toLocaleDateString()}`;
    } 

    let teamFilterOptions = [];    
    let teamIds = teamFilterValue.map(t => t.value);        
    
    if (props.standings !== undefined) {
        let allTeams = [...props.standings[0].teams, ...props.standings[1].teams, ...props.standings[2].teams, ...props.standings[3].teams];    
        let sortedTeams = allTeams.sort((a, b) => a.abbrev.localeCompare(b.abbrev));
        sortedTeams.forEach( t => 
            teamFilterOptions.push({ label: t.name, value: t.id })
            );
        
    }

    const teamFilterChanged = (t) => {
        SettingStorage.setStorageValue("nhlcountries_teamFilter", JSON.stringify(t));     
        setTeamFilter(t);
    }

    return (        
        <div>   
            <div style={{ width: "100%", alignItems: "end"}}>                                        
                <div style={{ width: "40%", float: "right"}}>
                    <MultiSelect                                
                        options={teamFilterOptions}
                        value={teamFilterValue}
                        onChange={teamFilterChanged}
                        labelledBy="Choose teams"
                        hasSelectAll={false}
                        disableSearch={true}
                        isCreatable={false}
                        overrideStrings=
                        {{
                            "allItemsAreSelected": "",
                            "clearSearch": "",
                            "clearSelected": "",
                            "noOptions": "",
                            "search": "",
                            "selectAll": "",
                            "selectAllFiltered": "",
                            "selectSomeItems": t("upcomingGames_showAllTeams"),
                            "create": ""
                        }}
                    />
                </div>                        
            </div>            
            {
                Object.entries(props.upcomingDateGames).map(([date, value]) => 
                    <Scores scores={value} 
                            players={props.players} 
                            standings={props.standings}
                            title={getFormattedDate(date)}
                            showAllDataSelector="false"
                            showTeamFilter={true}
                            teamIds={teamIds}/>
                )
            }      
        </div>        
    );
}

export default UpcomingGames;