class SettingStorage {
    static setCountry(dataSource){
        localStorage.setItem("country", dataSource);
    }

    static getCountry() {
        let dataSource = localStorage.getItem("country");
        if (dataSource === null){
            dataSource = "FIN";
        }
        return dataSource;
    }
    
    static setLanguage(dataSource){
        localStorage.setItem("nhlcountries_language", dataSource);
    }

    static getLanguage() {
        let dataSource = localStorage.getItem("nhlcountries_language");
        if (dataSource === null){
            dataSource = "en";
        }
        return dataSource;
    }

    static setShowAllData(dataSource){
        localStorage.setItem("nhlcountries_showAllData", dataSource);
    }

    static getShowAllData() {
        let dataSource = localStorage.getItem("nhlcountries_showAllData");
        if (dataSource === null){
            dataSource = false;
        }
        return dataSource;
    }

    static setSkaterSortOrder(value){
        localStorage.setItem("nhlcountries_skaterSortOrder", value);
    }

    static getSkaterSortOrder() {
        let value = localStorage.getItem("nhlcountries_skaterSortOrder");
        if (value === null){
            value = "pointsDesc";
        }
        return value;
    }

    static setGoalieSortOrder(value){
        localStorage.setItem("nhlcountries_goalieSortOrder", value);
    }

    static getGoalieSortOrder() {
        let value = localStorage.getItem("nhlcountries_goalieSortOrder");
        if (value === null){
            value = "savePctDesc";
        }
        return value;
    }

    static setSkaterSummarySortOrder(value){
        localStorage.setItem("nhlcountries_skaterSummarySortOrder", value);
    }

    static getSkaterSummarySortOrder() {
        let value = localStorage.getItem("nhlcountries_skaterSummarySortOrder");
        if (value === null){
            value = "pointsLatestDesc";
        }
        return value;
    }

    static setGoalieSummarySortOrder(value){
        localStorage.setItem("nhlcountries_goalieSummarySortOrder", value);
    }

    static getGoalieSummarySortOrder() {
        let value = localStorage.getItem("nhlcountries_goalieSummarySortOrder");
        if (value === null){
            value = "savePctLatestDesc";
        }
        return value;
    }

    static setStorageValue(name, value){
        localStorage.setItem(name, value);
    }

    static getStorageValue(name, defaultValue) {
        let value = localStorage.getItem(name);
        if (value === null){
            value = defaultValue;
        }
        return value;
    }
}

export default SettingStorage;