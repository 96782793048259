import PlayoffPairTable from '../components/PlayoffPairTable';
import './PlayoffBracket.css';
import { useTranslation } from "react-i18next";

const PlayoffBracket = (props) => {        
    const { t } = useTranslation();
    let allTeams = [...props.standings[0].teams, ...props.standings[1].teams, ...props.standings[2].teams, ...props.standings[3].teams];    

    return (        
        <div style={{ width: `100%;`, maxWidth: `500px;`}}>   
            <div className='Description'>{t("plBracket_description")}</div>                     
            <div className="TableContainer">
                <PlayoffPairTable 
                    title={t("plBracket_east")} 
                    teams={allTeams.filter(t => t.confAbbrev === "E")} 
                    players={props.players}/>
                <br/>
                <PlayoffPairTable 
                    title={t("plBracket_west")} 
                    teams={allTeams.filter(t => t.confAbbrev === "W")} 
                    players={props.players}/>                
                <br/>      
                <table>
                    <tr>
                        <td>{t("plBracket_wc")}</td>
                        <td>{t("plBracket_wc_l")}</td>
                    </tr>
                    <tr>
                        <td>{t("standings_gr")}</td>
                        <td>{t("standings_l_gr")}</td>
                    </tr>
                    <tr>
                        <td>{t("standings_rw")}</td>
                        <td>{t("standings_l_rw")}</td>
                    </tr>
                    <tr>
                        <td>{t("standings_row")}</td>
                        <td>{t("standings_l_row")}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{t("standings_highlight")}</td>                    
                    </tr>
                </table>           
            </div>              
        </div>        
    );
}

export default PlayoffBracket;