import './Standings.css';
import { useTranslation } from "react-i18next";

const Standings = (props) => {        
    const { t } = useTranslation();

    const headers = [];          
    headers.push("");
    headers.push("");    
    headers.push("");    
    headers.push(t("standings_games"));            
    headers.push(t("standings_points"));
    headers.push(t("standings_wins"));
    headers.push(t("standings_losses"));
    headers.push(t("standings_ot"));
    headers.push(t("standings_rw"));
    headers.push(t("standings_row"));
        
    function hasPlayer(teamId) {
        return (props.players.find(p => p.teamId === teamId && p.isActive) !== undefined) ? 
            "TeamCellHighlight" : "TeamCell";
    }

    return (        
        <div style={{ width: `100%;`, maxWidth: `500px;`}}>                        
            <div className="TableContainer">
                {props.standings.map((division) => (
                    <div>
                        <div>{division.name}</div>
                        <table className="Table_Standings">
                        <tr key={"header"}>
                        {headers.map((header) => (
                        <th className="HeaderCell">{header}</th>
                        ))}
                        </tr>
                        {division.teams.map((item, index) => (                        
                        <tr className="TableRow" key={item.id} >        
                            <td className="LeftJustifiedCell">{index + 1}</td>
                            <td className="LeftJustifiedCell">{item.clinch}</td>                                                          
                            <td className={hasPlayer(item.id)}>{item.name}</td>                                       
                            <td className="RightJustifiedCell">{item.games}</td>                            
                            <td className="RightJustifiedCellHighlight">{item.points}</td>                                                       
                            <td className="RightJustifiedCell">{item.wins}</td>
                            <td className="RightJustifiedCell">{item.losses}</td>
                            <td className="RightJustifiedCell">{item.ot}</td>                    
                            <td className="RightJustifiedCell">{item.rw}</td>
                            <td className="RightJustifiedCell">{item.row}</td>                                                    
                        </tr>
                        ))}                                        
                        </table>                
                        <br/>
                    </div>                    
                ))} 
                <table>
                <tr>
                    <td>{t("standings_rw")}</td>
                    <td>{t("standings_l_rw")}</td>
                </tr>
                <tr>
                    <td>{t("standings_row")}</td>
                    <td>{t("standings_l_row")}</td>
                </tr>
                <tr>
                    <td colSpan={2}>{t("standings_highlight")}</td>                    
                </tr>
            </table>           
            </div>              
        </div>        
    );
}

export default Standings;