import './PlayoffPairTable.css';
import PlayoffPair from '../components/PlayoffPair';
import { useTranslation } from "react-i18next";

const PlayoffPairTable = (props) => {  
    const { t } = useTranslation();

    let c1 = props.teams.find(t => t.divPos === 1 && t.confPos === 1);
    let c2 = props.teams.find(t => t.divPos === 1 && t.confPos !== 1);
    let d12 = props.teams.find(t => (t.divAbbrev === "M" || t.divAbbrev === "C") && t.divPos === 2);
    let d13 = props.teams.find(t => t.divAbbrev === d12.divAbbrev && t.divPos === 3);
    let d22 = props.teams.find(t => (t.divAbbrev === "A" || t.divAbbrev === "P") && t.divPos === 2);
    let d23 = props.teams.find(t => t.divAbbrev === d22.divAbbrev && t.divPos === 3);
    let wc1 = props.teams.find(t => t.wcPos === 1);
    let wc2 = props.teams.find(t => t.wcPos === 2);
    let wc3 = props.teams.find(t => t.wcPos === 3);
    let wc4 = props.teams.find(t => t.wcPos === 4);
    let wc5 = props.teams.find(t => t.wcPos === 5);

    return (
        props.teams !== undefined ?        
            <div>
                <h4>{props.title}</h4>
                <table className="Table_Standings">      
                    <tr>
                        <th className='Cell'></th>
                        <th className='TeamCell'></th>
                        <th className='Cell'>{t("standings_points")}</th>
                        <th className='Cell'>{t("standings_gr")}</th>
                        <th className='Cell'>{t("standings_rw")}</th>
                        <th className='Cell'>{t("standings_row")}</th>
                    </tr>
                        <PlayoffPair players={props.players} team={c1}/>
                        <PlayoffPair players={props.players} team={wc2}/>
                        <tr><td colspan="2">&nbsp;</td></tr>
                        <PlayoffPair players={props.players} team={c2}/>
                        <PlayoffPair players={props.players} team={wc1}/>
                        <tr><td colspan="2">&nbsp;</td></tr>                        
                        <PlayoffPair players={props.players} team={d12}/>
                        <PlayoffPair players={props.players} team={d13}/>
                        <tr><td colspan="2">&nbsp;</td></tr>
                        <PlayoffPair players={props.players} team={d22}/>
                        <PlayoffPair players={props.players} team={d23}/>
                        <tr><td colspan="2">&nbsp;</td></tr>                
                    
                </table>                
                <table>
                    <tr>
                        <td>{t("plBracket_underLine")}:</td>
                    </tr>
                    <PlayoffPair players={props.players} team={wc3}/>
                    <PlayoffPair players={props.players} team={wc4}/>
                    <PlayoffPair players={props.players} team={wc5}/>                    
                </table>
            </div>       
         : <div/>
    );
}

export default PlayoffPairTable;