import React from 'react';
import './Home.css';
import { MDBContainer } from "mdbreact";
import SettingStorage from '../classes/SettingsStorage';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LatestGameDay from '../LatestGameDay/LatestGameDay'
import PointsPerGameDay from '../PointsPerGameDay/PointsPerGameDay';
import NationalTeam from '../NationalTeam/NationalTeam';
import Stats from '../Stats/Stats';
import 'react-tabs/style/react-tabs.css';
import Scores from '../Scores/Scores';
import Legend from '../Scores/Legend';
import { useTranslation } from "react-i18next";
import Utilities from "../classes/Utilities";
import Standings from '../Standings/Standings';
import News from '../News/News';
import PlayoffBracket from '../PlayoffBracket/PlayoffBracket';
import UpcomingGames from '../UpcomingGames/UpcomingGames';

const Home = (props) => { 
    let currentCountry = SettingStorage.getCountry();  
    let currentLanguage = SettingStorage.getLanguage(); 
    const [countryValue, setValue] = React.useState(currentCountry);
    const [langValue, setLanguage] = React.useState(currentLanguage);
    const { t, i18n } = useTranslation();
    
    if (i18n.language !== currentLanguage)
    {
        i18n.changeLanguage(currentLanguage);
    }    
    
    let isLoading = props.isLoading;
    const updating = <div className='Center'>{t("home_updatingData")}</div>;
    if (props === undefined || props.data === null || props.data.players === null) {
        return (<p>No data</p>);        
    }
    else if (props.data === undefined || props.data.players === undefined) {        
        isLoading = true;        
    }    
    else if (props.data.players.length === 0)
    {
        return (<div className="Progress"><h4>{t("home_noPlayers")}</h4></div>);
    }        
       
    let fiLangStyle = currentLanguage === "en" ? "XSmallText" : "XSmallTextUnderlined";
    let enLangStyle = currentLanguage === "en" ? "XSmallTextUnderlined" : "XSmallText";

    const handleCountryChange = (event) => {
        setValue(event.target.value);        
        SettingStorage.setCountry(event.target.value);        
        props.countryChanged();
      };      
    
    const onEnglishSelected = () => {        
        if (i18n.currentLanguage !== "en")
        {
            setLanguage("en");
            i18n.changeLanguage("en");        
            SettingStorage.setLanguage("en");                
        }        
        enLangStyle = "XSmallTextUnderlined";
        fiLangStyle = "XSmallText";         
    }

    const onFinnishSelected = () => {
        if (i18n.currentLanguage !== "fi")
        {
            setLanguage("fi");
            i18n.changeLanguage("fi");        
            SettingStorage.setLanguage("fi");                
        }   
        fiLangStyle = "XSmallTextUnderlined";         
        enLangStyle = "XSmallText";         
    }

    const onUpdatedClicked = () => {
        window.alert('v0.' + props.appVersion);
    }

    let lastUpdatedString = "";

    if (props.lastUpdated !== undefined)
    {
        const lastUpdated = new Date(props.lastUpdated);
        lastUpdatedString = String(lastUpdated.getHours()) + ':' + String(lastUpdated.getMinutes()).padStart(2, '0') + ":" + String(lastUpdated.getSeconds()).padStart(2, '0');
    }    

    var currentRoundTime = Utilities.getCurrentRoundDate();
    var currentDate = `${Utilities.getWeekday(t, currentRoundTime)} ${currentRoundTime.toLocaleDateString()}`
    
    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        return `${Utilities.getWeekday(t, date)} ${date.toLocaleDateString()}`;
    } 

    let currentDateGames = [];
    let upcomingDateGames = {};
    const currentDateString = String(currentRoundTime.getFullYear()) + "-" + String(currentRoundTime.getMonth() + 1).padStart(2, '0') + "-" + String(currentRoundTime.getDate()).padStart(2, '0');
    let games = {};
    if (props.data.scores !== undefined)
    {        
        games = Utilities.groupBy(props.data.scores, 'date'); 
        currentDateGames = Object.keys(games)[0] === currentDateString ? games[currentDateString] : [];

        for (let date in games)
        {
            if (games[date][0].isUpcoming)
            {
                upcomingDateGames[date] = games[date];
            }
        }                
    }    

    const note = ""; //"Ironing out playoff data related problems may take few days...";
    
    return (
        <MDBContainer className="Chart">          
          <div className="Page">
            <div className="DivHomeHeader">            
                    <div className="DivLang">       
                        <label className={enLangStyle} onClick={onEnglishSelected}>English</label>
                        <label> | </label>
                        <label className={fiLangStyle} onClick={onFinnishSelected}>Suomi</label>
                    </div>
                    {/* <h3 className="Heading">{t("app_name")} <span class="SmallHeading">v0.{props.appVersion}</span></h3> */}
                    <h3 className="Heading">{t("app_name")}</h3>
            </div>
            {/* <p>{t("home_statApiDown1")}</p> */}
            <table className='Home_HeaderTable'>
                <tr>                    
                    <td style={{ width: `1%`, whitespace: `nowrap;` }}>
                        <div>
                        <label className="DropdownLabel">{t("main_countrySelectionLabel")}: </label>                        
                            <select className="Dropdown" value={countryValue} onChange={handleCountryChange}>
                                <option value="FIN">{t("country_finland")}</option>
                                <option value="SWE">{t("country_sweden")}</option>
                                <option value="CZE">{t("country_czechia")}</option>
                                <option value="CAN">{t("country_canada")}</option>
                                <option value="USA">{t("country_usa")}</option>
                                <option value="RUS">{t("country_russia")}</option>
                                <option value="DEU">{t("country_germany")}</option>
                                <option value="FRA">{t("country_france")}</option>
                                <option value="CHE">{t("country_switzerland")}</option>
                                <option value="SVK">{t("country_slovakia")}</option>
                                <option value="NOR">{t("country_norway")}</option>
                                <option value="DNK">{t("country_denmark")}</option>
                                <option value="LVA">{t("country_latvia")}</option>
                            </select>
                        
                        </div>
                    </td>                    
                    <td className='NoWrap'>                    
                        <label className='XSmallRightFloatText' onClick={onUpdatedClicked}>{lastUpdatedString}</label>     
                    </td>                    
                    <td className='NoWrap'>                    
                        <label className='XSmallRightFloatText' onClick={onUpdatedClicked}>--</label>     
                    </td>                    
                    <td className='NoWrap'>                    
                        <label className='XSmallRightFloatText' onClick={onUpdatedClicked}>v0.{props.appVersion}</label>     
                    </td>                    
                    
                    
                </tr>
                <tr>
                    <td className='XSmallText' colSpan={2} style={{ textAlign: "center" }}>{note}</td>
                </tr>
            </table>                               
            <div>
            <Tabs className="TabControl">
                <TabList>
                <Tab>{t("main_gamesLabel")}</Tab>
                <Tab>{t("main_lastNightLabel")}</Tab>                
                <Tab>{t("main_upcomingLabel")}</Tab>                
                <Tab>{props.data.length !== 0 && props.data.points.length !== 0 ? (Math.floor(Math.max.apply(null, Object.values(props.data.points)) / 5) + 1) * 5 : 5}p</Tab>                
                <Tab>{t("main_statsLabel")}</Tab>
                <Tab>{t("main_newsLabel")}</Tab>
                <Tab>{t("main_nationalTeamLabel")}</Tab>
                <Tab>{t("main_standingsLabel")}</Tab>
                <Tab>{t("main_playoffBracketLabel")}</Tab>
                </TabList> 
                <TabPanel>          
                    {props.data.players !== undefined ?
                    <div className='TabContent'>                              
                        <div>
                            <Scores scores={currentDateGames} 
                            players={props.data.players} 
                            title={currentDate}
                            showAllDataSelector="true"
                            showTeamFilter={false}/>                                                
                            
                            <Legend scores={currentDateGames}
                            players={props.data.players} 
                            showAlways={false}/>
                        </div>                  
                    </div> : updating}
                </TabPanel>               
                <TabPanel>                              
                    {props.data.players !== undefined ?
                    <div className='TabContent'>
                        <LatestGameDay data={props.data}/>                        
                    </div> : updating}
                </TabPanel>                
                <TabPanel>          
                    {props.data.players !== undefined ?
                    <div className='TabContent'>
                        <UpcomingGames
                            players={props.data.players}
                            standings={props.data.standings}
                            upcomingDateGames={upcomingDateGames}/>
                        <Legend scores={props.data.scores.filter(g => g.isUpcoming)}
                                players={props.data.players} 
                                showAlways={false}/>
                    </div> : updating}
                </TabPanel>                               
                <TabPanel>          
                    {props.data !== undefined ?
                    <div>                        
                        <PointsPerGameDay data={props.data}/>
                        </div> : updating} 
                </TabPanel>          
                <TabPanel>
                    {props.data.players !== undefined ?
                    <div>
                        <Stats 
                        players={props.data.players}
                        scores={props.data.scores}
                        updateId={props.updateId}
                        allPlayers={props.allPlayers}
                        allPlayerDataChanged={props.allPlayerDataChanged}/>                        
                    </div> : updating}             
                </TabPanel>
                <TabPanel>                
                    {props.data.players !== undefined ?
                    <div>
                        <News
                            players={props.data.players}/>                        
                    </div> : updating}                                 
                </TabPanel>
                <TabPanel>                
                    {props.data.players !== undefined ?
                    <div>
                        <NationalTeam 
                            players={props.data.players}
                            standings={props.data.standings}/>
                    </div> : updating}    
                </TabPanel>
                <TabPanel>                
                    {props.data.players !== undefined ?
                    <div>
                        <Standings 
                            standings={props.data.standings}
                            players={props.data.players}/>                        
                    </div> : updating}                                 
                </TabPanel>                
                <TabPanel>                
                    {props.data.players !== undefined ?
                    <div>
                        <PlayoffBracket 
                            standings={props.data.standings}
                            players={props.data.players}/>                        
                    </div> : updating}                                 
                </TabPanel>      
            </Tabs> 
            </div>
        </div>
        </MDBContainer>
      );
}

export default Home;