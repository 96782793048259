import React from 'react';
import './Scores.css';
import { useTranslation } from "react-i18next";
import '../index.css'

const Legend = (props) => {    
    const { t } = useTranslation();    
    let showLegend = false;

    if (props.showAlways)
    {        
        if (props.showAllPlayers)
        {
            if (props.allPlayers !== undefined)
            {
                const injuredPlayers = props.allPlayers.filter(p => p.injury !== 0);
                showLegend = injuredPlayers.length > 0;    
            }            
        }
        else
        {
            const injuredPlayers = props.players.filter(p => p.injury !== 0);
            showLegend = injuredPlayers.length > 0;
        }        
    }
    else
    {
        const injuredPlayers = props.players.filter(p => p.injury !== 0);
        const upcomingGames = props.scores.filter(g => g.status === "1" || g.status === "2" || g.status === "9");
        upcomingGames.forEach((game) =>
        {
            if (injuredPlayers.find(p => p.teamId === game.homeTeamId || p.teamId === game.awayTeamId) !== undefined)
                showLegend = true;
        });
    }    

    return (
        <div>               
        {
        (showLegend &&
        <table>
            <tr>
                <td>*</td>
                <td>{t("scores_legend_dtd")}</td>
            </tr>
            <tr>
                <td>**</td>
                <td>{t("scores_legend_out")}</td>
            </tr>
            <tr>
                <td>***</td>
                <td>{t("scores_legend_ir")}</td>
            </tr>
        </table>
        )
        }
        </div>        
    );
}

export default Legend;