import './NationalTeamPlayer.css';

const NationalTeamPlayer = (props) => {        
    
    const getImageAddress = (player) => {        
        return player !== undefined ? `https://assets.nhle.com/mugs/nhl/20232024/${player.team}/${player.playerId}.png` : undefined;
    }

    return (
        props.player !== undefined ?
        <div>
            <img className="Image" src={getImageAddress(props.player)} alt={""}/> 
            <div className='PlayerName'>{props.player.displayName}</div>            
        </div> : <div/>
    );
}

export default NationalTeamPlayer;