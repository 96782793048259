class Converters {
    static GetGameStatus(sourceStatus) {
        // 1 - Scheduled, 2 - pre-game, 8 - Scheduled (Time TBD)
        if (sourceStatus === "1" || sourceStatus === "2" || sourceStatus === "8")
        {
            return 0;
        }
        // Postponed
        else if (sourceStatus === "9")
        {
            return 1;
        }
        // 3 - In progress, 4 - In progress (critical)
        if (sourceStatus === "3" || sourceStatus === "4")
        {
            return 2;
        }
        // 5 - Game over, 6 - Final, 7 - Final
        if (sourceStatus === "5" || sourceStatus === "6" || sourceStatus === "7")
        {
            return 3;
        }
    }

    static GetPlatformType(userAgent) {
        // Mobile
        if (/(iPhone|iPod|iPad|Android)/i.test(userAgent))
        {
            // Chrome on Android seems not to be able to handle M3U8 files properly
            if (/(Android)/i.test(userAgent) && /(Chrome)/i.test(userAgent))
            {
                return "Desktop";
            }
            return "Mobile";
        }
        // Desktop
        else if (/(Windows|Mac|Linux)/i.test(userAgent))
        {
            return "Desktop";
        }
    }
}
export default Converters;