import './PlayoffPair.css';
import { useTranslation } from "react-i18next";

const PlayoffPair = (props) => {  
    const { t } = useTranslation();
    function hasPlayer(teamId) {
        return (props.players.find(p => p.teamId === teamId && p.isActive) !== undefined) ? 
            "TeamCellHighlight" : "TeamCell";
    }
    function hasPlayerRow(teamId) {
        return (props.players.find(p => p.teamId === teamId && p.isActive) !== undefined) ? 
            "RowHighlight" : "Row";
    }
    return (
        props.team !== undefined ?        
            <tr className={hasPlayerRow(props.team.id)}>
                <td className='Cell'>{props.team.wcPos > 0 ? (props.team.wcPos > 2 ? props.team.divAbbrev + props.team.divPos : t("plBracket_wc") + props.team.wcPos) : props.team.divAbbrev + props.team.divPos}</td>  
                <td className={hasPlayer(props.team.id)}> {props.team.name}</td>                                                
                <td className='Cell'>{props.team.points}</td>
                <td className='Cell'>{82 - props.team.games}</td>
                <td className='Cell'>{props.team.rw}</td>
                <td className='Cell'>{props.team.row}</td>                
            </tr>        
         : <tr/>
    );
}

export default PlayoffPair;