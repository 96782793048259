import './LatestGameDay.css';
import React from 'react';
import { useTranslation } from "react-i18next";
import Utilities from '../classes/Utilities';
import SettingStorage from '../classes/SettingsStorage';

const LatestGameDay = (props) => {
    const { t } = useTranslation();

    let skaterSortOrder = SettingStorage.getSkaterSummarySortOrder();       
    let goalieSortOrder = SettingStorage.getGoalieSummarySortOrder();    
    const [skaterSortOrderValue, setSkaterSortOrderValue] = React.useState(skaterSortOrder);    
    const [goalieSortOrderValue, setGoalieSortOrderValue] = React.useState(goalieSortOrder);        
    let skaterSortColumnIndex = 0;
    let goalieSortColumnIndex = 0;
    let skaterSortCaption = "";
    let goalieSortCaption = "";
    
    const handleSkaterOrderChangeHeader = (id) => {        
        let newOrderValue;
        if (id + "Asc" === skaterSortOrderValue)
        {
            newOrderValue = id + "Desc";
        }
        else if (id + "Desc" === skaterSortOrderValue)
        {
            newOrderValue = id + "Asc";
        }
        else{
            newOrderValue = id + "Desc";
        }
        setSkaterSortOrderValue(newOrderValue);        
        SettingStorage.setSkaterSummarySortOrder(newOrderValue);                
      };

    const handleGoalieOrderChangeHeader = (id) => {        
        let newOrderValue;
        if (id + "Asc" === goalieSortOrderValue)
        {
            newOrderValue = id + "Desc";
        }
        else if (id + "Desc" === goalieSortOrderValue)
        {
            newOrderValue = id + "Asc";
        }
        else{
            newOrderValue = id + "Desc";
        }
        setGoalieSortOrderValue(newOrderValue);        
        SettingStorage.setGoalieSummarySortOrder(newOrderValue);                
      };

    const latestGameHeaders = [];    
    latestGameHeaders.push({ name: "#", caption: "#", index: -1 });
    latestGameHeaders.push({ name: "name", caption: t("statColumn_name"), index: 0 });    
    latestGameHeaders.push({ name: "team", caption: t("statColumn_team"), index: 1 });            
    latestGameHeaders.push({ name: "goalsLatest", caption: t("statColumn_goals"), index: 17 });
    latestGameHeaders.push({ name: "assistsLatest", caption: t("statColumn_assists"), index: 18 });
    latestGameHeaders.push({ name: "pointsLatest", caption: t("statColumn_points"), index: 19 });
    latestGameHeaders.push({ name: "toiLatest", caption: t("statColumn_toi"), index: 20 });        
    latestGameHeaders.push({ name: "plusMinusLatest", caption: t("statColumn_plusMinus"), index: 21 });    
    latestGameHeaders.push({ name: "pimLatest", caption: t("statColumn_penaltyMinutes"), index: 22 }); 
    latestGameHeaders.push({ name: "shotsLatest", caption: t("statColumn_shots"), index: 23 });      
    
    const goalieHeaders = [];   
    goalieHeaders.push({ name: "#", caption: "#", index: -1 });
    goalieHeaders.push({ name: "name", caption: t("statColumn_name"), index: 0 });    
    goalieHeaders.push({ name: "team", caption: t("statColumn_team"), index: 1 });            
    goalieHeaders.push({ name: "savesLatest", caption: t("statColumn_saves"), index: 13 });
    goalieHeaders.push({ name: "shotsAgainstLatest", caption: t("statColumn_shotsAgainst"), index: 12 });
    goalieHeaders.push({ name: "savePctLatest", caption: t("statColumn_savePct"), index: 14 });    
    goalieHeaders.push({ name: "toiLatest", caption: t("statColumn_toi"), index: 15 });
    
    let currentDayPoints = Utilities.getCountryPointsFromScores(props.data.scores, props.data.players);           
    const currentDate = Utilities.getCurrentRoundDate();
    const currentDateString = `${currentDate.getUTCFullYear()}-${Utilities.pad((currentDate.getUTCMonth() + 1), 2)}-${Utilities.pad(currentDate.getUTCDate(), 2)}`;
    props.data.points[currentDateString] = currentDayPoints;    

    let points = [];    
    let keys = Object.keys(props.data.points).sort();        
    
    for (let dateId in keys)
    {        
        points.push(props.data.points[keys[dateId]]);
    }

    let latestDate = keys.at(-1);    
    let skaters = sort("nameAsc",
        props.data.players.filter(p => p.primaryPosition !== "G").filter(p => p.latestGame !== undefined && p.latestGame.date === latestDate), true);
    skaters = sort(skaterSortOrderValue, skaters, true);
    let goalies = sort("nameAsc",
            props.data.players.filter(p => p.primaryPosition === "G").filter(p => p.latestGame !== undefined && p.latestGame.date === latestDate), false);    
    goalies = sort(goalieSortOrderValue, goalies, false);
    let skaterCounter = 1;    
    let goalieCounter = 1;      

    const weekday = Utilities.getWeekday(t, latestDate);
    
    function GetSkaterHeaderHighlightStyle(index)
    {
        return index === skaterSortColumnIndex ? { backgroundColor: "#555555" } : { backgroundColor: "#000000" };            
    }

    function GetGoalieHeaderHighlightStyle(index)
    {
        return index === goalieSortColumnIndex ? { backgroundColor: "#555555" } : { backgroundColor: "#000000" };            
    }

    function getSortCaption(fullColumnCaption) {
        return `${t("stats_sortLabel")}: ${fullColumnCaption}`;
    }

    function GetHighlightStyle(player, index)
    {        
        if (index === skaterSortColumnIndex)   
        {
            return { color: "white", backgroundColor: "#383838" };    
        }
        return { color: "white" };
    }

    function GetGoalieHighlightStyle(player, index)
    {        
        if (index === goalieSortColumnIndex)   
        {
            return { color: "white", backgroundColor: "#383838" };    
        }
        return { color: "white" };
    }

    function sort(order, players, isSkater)
    {
        if (order === "nameDesc")
        {   
            if (isSkater) {
                skaterSortColumnIndex = 0;
                skaterSortCaption = getSortCaption(t("scFull_name"));
            }                
            else {                
                goalieSortColumnIndex = 0;            
                goalieSortCaption = getSortCaption(t("scFull_name"));
            }
            
            return players.sort((a, b) => (b.lastName > a.lastName) - (b.lastName < a.lastName));
        }
        if (order === "nameAsc")
        {
            if (isSkater) {
                skaterSortColumnIndex = 0;
                skaterSortCaption = getSortCaption(t("scFull_name"));
            }                
            else {                
                goalieSortColumnIndex = 0;            
                goalieSortCaption = getSortCaption(t("scFull_name"));
            }

            return players.sort((a, b) => (a.lastName > b.lastName) - (a.lastName < b.lastName));
        }
        if (order === "teamDesc")
        {
            if (isSkater) {
                skaterSortColumnIndex = 1;
                skaterSortCaption = getSortCaption(t("scFull_team"));
            }                
            else {                
                goalieSortColumnIndex = 1;            
                goalieSortCaption = getSortCaption(t("scFull_team"));
            }

            return players.sort((a, b) => (b.team > a.team) - (b.team < a.team));
        }
        if (order === "teamAsc")
        {
            if (isSkater) {
                skaterSortColumnIndex = 1;
                skaterSortCaption = getSortCaption(t("scFull_team"));
            }                
            else {                
                goalieSortColumnIndex = 1;            
                goalieSortCaption = getSortCaption(t("scFull_team"));
            }
            
            return players.sort((a, b) => (a.team > b.team) - (a.team < b.team));
        }
        
        if (order === "goalsLatestDesc")
        {
            skaterSortColumnIndex = 17;
            skaterSortCaption = getSortCaption(t("scFull_goals"));
            return players.sort((a, b) => b.latestGame.goals - a.latestGame.goals);
        }
        if (order === "goalsLatestAsc")
        {
            skaterSortColumnIndex = 17;
            skaterSortCaption = getSortCaption(t("scFull_goals"));
            return players.sort((a, b) => a.latestGame.goals - b.latestGame.goals);
        }
        if (order === "assistsLatestDesc")
        {
            skaterSortColumnIndex = 18;
            skaterSortCaption = getSortCaption(t("scFull_assists"));
            return players.sort((a, b) => b.latestGame.assists - a.latestGame.assists);
        }
        if (order === "assistsLatestAsc")
        {
            skaterSortColumnIndex = 18;
            skaterSortCaption = getSortCaption(t("scFull_assists"));
            return players.sort((a, b) => a.latestGame.assists - b.latestGame.assists);
        }
        if (order === "pointsLatestDesc")
        {
            skaterSortColumnIndex = 19;
            skaterSortCaption = getSortCaption(t("scFull_points"));
            return players.sort((a, b) => b.latestGame.points - a.latestGame.points || b.latestGame.goals - a.latestGame.goals);
        }
        if (order === "pointsLatestAsc")
        {
            skaterSortColumnIndex = 19;
            skaterSortCaption = getSortCaption(t("scFull_points"));
            return players.sort((a, b) => a.latestGame.points - b.latestGame.points || a.latestGame.goals - b.latestGame.goals);
        }
        else if (order === "toiLatestDesc")
        {         
            if (isSkater) {
                skaterSortColumnIndex = 20;
                skaterSortCaption = getSortCaption(t("scFull_toi"));
                return players.sort((a, b) => (b.latestGame.timeOnIce > a.latestGame.timeOnIce) - (b.latestGame.timeOnIce < a.latestGame.timeOnIce));
            }                
            else {                
                goalieSortColumnIndex = 15;            
                goalieSortCaption = getSortCaption(t("scFull_toi"));
                return players.sort((a, b) => (b.latestGame.toi > a.latestGame.toi) - (b.latestGame.toi < a.latestGame.toi));
            }

            
        }
        else if (order === "toiLatestAsc")
        {
            if (isSkater) {
                skaterSortColumnIndex = 20;
                skaterSortCaption = getSortCaption(t("scFull_toi"));
                return players.sort((a, b) => (a.latestGame.timeOnIce > b.latestGame.timeOnIce) - (a.latestGame.timeOnIce < b.latestGame.timeOnIce));
            }                
            else {                
                goalieSortColumnIndex = 15;            
                goalieSortCaption = getSortCaption(t("scFull_toi"));
                return players.sort((a, b) => (a.latestGame.toi > b.latestGame.toi) - (a.latestGame.toi < b.latestGame.toi));
            }
            
        }
        else if (order === "plusMinusLatestDesc")
        {
            skaterSortColumnIndex = 21;
            skaterSortCaption = getSortCaption(t("scFull_plusMinus"));
            return players.sort((a, b) => b.latestGame.plusMinus - a.latestGame.plusMinus);
        }
        else if (order === "plusMinusLatestAsc")
        {
            skaterSortColumnIndex = 21;
            skaterSortCaption = getSortCaption(t("scFull_plusMinus"));
            return players.sort((a, b) => a.latestGame.plusMinus - b.latestGame.plusMinus);
        }
        else if (order === "shotsLatestDesc")
        {
            skaterSortColumnIndex = 23;
            skaterSortCaption = getSortCaption(t("scFull_shotPct"));
            return players.sort((a, b) => b.latestGame.shots - a.latestGame.shots);
        }
        else if (order === "shotsLatestAsc")
        {
            skaterSortColumnIndex = 23;
            skaterSortCaption = getSortCaption(t("scFull_shotPct"));
            return players.sort((a, b) => a.latestGame.shots - b.latestGame.shots);
        }
        else if (order === "pimLatestDesc")
        {
            skaterSortColumnIndex = 22;
            skaterSortCaption = getSortCaption(t("scFull_pim"));
            return players.sort((a, b) => b.latestGame.pim - a.latestGame.pim);
        }
        else if (order === "pimLatestAsc")
        {
            skaterSortColumnIndex = 22;
            skaterSortCaption = getSortCaption(t("scFull_pim"));
            return players.sort((a, b) => a.latestGame.pim - b.latestGame.pim);
        }


        // Goalie
        else if (order === "shotsAgainstDesc")
        {
            goalieSortColumnIndex = 4;
            goalieSortCaption = getSortCaption(t("scFull_shotsAgainst"));
            return players.sort((a, b) => b.shotsAgainst - a.shotsAgainst);
        }
        else if (order === "shotsAgainstAsc")
        {
            goalieSortColumnIndex = 4;
            goalieSortCaption = getSortCaption(t("scFull_shotsAgainst"));
            return players.sort((a, b) => a.shotsAgainst - b.shotsAgainst);
        }
        else if (order === "savesDesc")
        {
            goalieSortColumnIndex = 3;
            goalieSortCaption = getSortCaption(t("scFull_saves"));
            return players.sort((a, b) => b.saves - a.saves);
        }
        else if (order === "savesAsc")
        {
            goalieSortColumnIndex = 3;
            goalieSortCaption = getSortCaption(t("scFull_saves"));
            return players.sort((a, b) => a.saves - b.saves);
        }
        else if (order === "savePctDesc")
        {
            goalieSortColumnIndex = 5;
            goalieSortCaption = getSortCaption(t("scFull_savePct"));
            return players.sort((a, b) => b.savePct - a.savePct);
        }
        else if (order === "savePctAsc")
        {
            goalieSortColumnIndex = 5;
            goalieSortCaption = getSortCaption(t("scFull_savePct"));
            return players.sort((a, b) => a.savePct - b.savePct);
        }
        else if (order === "winsDesc")
        {
            goalieSortColumnIndex = 8;
            goalieSortCaption = getSortCaption(t("scFull_wins"));
            return players.sort((a, b) => b.wins - a.wins);
        }
        else if (order === "winsAsc")
        {
            goalieSortColumnIndex = 8;
            goalieSortCaption = getSortCaption(t("scFull_wins"));
            return players.sort((a, b) => a.wins - b.wins);
        }
        else if (order === "lossesDesc")
        {
            goalieSortColumnIndex = 9;
            goalieSortCaption = getSortCaption(t("scFull_losses"));
            return players.sort((a, b) => b.losses - a.losses);
        }
        else if (order === "lossesAsc")
        {
            goalieSortColumnIndex = 9;
            goalieSortCaption = getSortCaption(t("scFull_losses"));
            return players.sort((a, b) => a.losses - b.losses);
        }
        else if (order === "otDesc")
        {
            goalieSortColumnIndex = 10;
            goalieSortCaption = getSortCaption(t("scFull_ot"));
            return players.sort((a, b) => b.ot - a.ot);
        }
        else if (order === "otAsc")
        {
            goalieSortColumnIndex = 10;
            goalieSortCaption = getSortCaption(t("scFull_ot"));
            return players.sort((a, b) => a.ot - b.ot);
        }
        else if (order === "gaaDesc")
        {
            goalieSortColumnIndex = 6;
            goalieSortCaption = getSortCaption(t("scFull_gaa"));
            return players.sort((a, b) => b.gaa - a.gaa);
        }
        else if (order === "gaaAsc")
        {
            goalieSortColumnIndex = 6;
            goalieSortCaption = getSortCaption(t("scFull_gaa"));
            return players.sort((a, b) => a.gaa - b.gaa);
        }
        else if (order === "shotsAgainstLatestDesc")
        {
            goalieSortColumnIndex = 12;
            goalieSortCaption = getSortCaption(t("scFull_shotsAgainst"));
            return players.sort((a, b) => b.latestGame.shotsAgainst - a.latestGame.shotsAgainst);
        }
        else if (order === "shotsAgainstLatestAsc")
        {
            goalieSortColumnIndex = 12;
            goalieSortCaption = getSortCaption(t("scFull_shotsAgainst"));
            return players.sort((a, b) => a.latestGame.shotsAgainst - b.latestGame.shotsAgainst);
        }
        else if (order === "savesLatestDesc")
        {
            goalieSortColumnIndex = 13;
            goalieSortCaption = getSortCaption(t("scFull_saves"));
            return players.sort((a, b) => b.latestGame.saves - a.latestGame.saves);
        }
        else if (order === "savesLatestAsc")
        {
            goalieSortColumnIndex = 13;
            goalieSortCaption = getSortCaption(t("scFull_saves"));
            return players.sort((a, b) => a.latestGame.saves - b.latestGame.saves);
        }
        else if (order === "savePctLatestDesc")
        {
            goalieSortColumnIndex = 14;
            goalieSortCaption = getSortCaption(t("scFull_savePct"));
            return players.sort((a, b) => b.latestGame.savePct - a.latestGame.savePct);
        }
        else if (order === "savePctLatestAsc")
        {
            goalieSortColumnIndex = 14;
            goalieSortCaption = getSortCaption(t("scFull_savePct"));
            return players.sort((a, b) => a.latestGame.savePct - b.latestGame.savePct);
        }
        
        return players.sort((a, b) => (a.displayName > b.displayName) - (a.displayName < b.displayName));
    }    

    return (
        <div>
            <h4>{weekday + " " + new Date(latestDate).toLocaleDateString()}</h4>                     
            <p>{t("latestGameDay_totalPoints")}: {points.at(-1)}</p>               
            {
                skaters.length > 0 &&
                <div>
                    <table className="LatestGameDay__table">
                        <tr>
                            <td style={{ width: `30%`, whitespace: `nowrap;` }}>
                                <h5 style={{ margin: `8px` }}>{t("stats_skaters")}</h5>            
                            </td>
                            <td style={{ width: `40%`, fontSize: `smaller`, textAlign: `right`, whitespace: `nowrap;`, marginLeft: `24px;`, maxWidth: `100px;` }}>                    
                                {skaterSortCaption}
                            </td>   
                        </tr>
                    </table>                    
                    <table className="LatestGameDay__table">
                        <tr key={"header"}>
                        {latestGameHeaders.map((header) => (
                    <th style={GetSkaterHeaderHighlightStyle(header.index)} onClick={() => handleSkaterOrderChangeHeader(header.name)}>{header.caption}</th>
                    ))}
                        </tr>
                        {skaters.map((item) => (
                        <tr key={item.id} className="LatestGameDay__tableRow">    
                            <td className="RightJustifiedCell" style={GetHighlightStyle(item, -1)}>{skaterCounter++}</td>                
                            <td className="LeftJustifiedCell" style={GetHighlightStyle(item, 0)}>{item.lastName} {item.firstName.at(0)}</td>                    
                            <td className="LeftJustifiedCell" style={GetHighlightStyle(item, 1)}>{item.team}</td>
                            <td className="RightJustifiedCell" style={GetHighlightStyle(item, 17)}>{item.latestGame.goals}</td>
                            <td className="RightJustifiedCell" style={GetHighlightStyle(item, 18)}>{item.latestGame.assists}</td>                                                
                            <td className="RightJustifiedCell" style={GetHighlightStyle(item, 19)}>{item.latestGame.assists + item.latestGame.goals}</td>                    
                            <td className="RightJustifiedCell" style={GetHighlightStyle(item, 20)}>{item.latestGame.timeOnIce}</td>                                            
                            {/* <td className="RightJustifiedCell">{item.latestGame.hits}</td> */}
                            <td className="RightJustifiedCell" style={GetHighlightStyle(item, 21)}>{item.latestGame.plusMinus}</td>
                            <td className="RightJustifiedCell" style={GetHighlightStyle(item, 22)}>{item.latestGame.pim}</td>                            
                            <td className="RightJustifiedCell" style={GetHighlightStyle(item, 23)}>{item.latestGame.shots}</td>
                            
                        </tr>
                        ))}
                    </table>    
                </div>
            }            
            {
                goalies.length > 0 &&
                <div>
                <table className="LatestGameDay__table">
                    <tr>
                        <td style={{ width: `30%`, whitespace: `nowrap;` }}>
                            <h5 style={{ margin: `8px`, marginTop: `16px` }}>{t("stats_goalies")}</h5>            
                        </td>
                        <td style={{ width: `40%`, fontSize: `smaller`, textAlign: `right`, whitespace: `nowrap;`, marginLeft: `24px;`, maxWidth: `100px;` }}>                    
                            {goalieSortCaption}
                        </td>   
                    </tr>
                </table>                
                <table className="LatestGameDay__table">
                    <tr key={"header"}>
                    {goalieHeaders.map((header) => (
                        <th style={GetGoalieHeaderHighlightStyle(header.index)} className="HeaderCell" onClick={() => handleGoalieOrderChangeHeader(header.name)}>{header.caption}</th>
                        ))}
                    </tr>
                    {goalies.map((item) => (
                    <tr className="TableRow" key={item.id} >        
                        <td className="RightJustifiedCell" style={GetGoalieHighlightStyle(item, -1)}>{goalieCounter++}</td>
                        <td className="LeftJustifiedCell" style={GetGoalieHighlightStyle(item, 0)}>{item.lastName} {item.firstName.at(0)}</td>                                                          
                        <td className="LeftJustifiedCell" style={GetGoalieHighlightStyle(item, 1)}>{item.team}</td>                                        
                        <td className="RightJustifiedCell" style={GetGoalieHighlightStyle(item, 13)}>{item.latestGame.saves}</td>
                        <td className="RightJustifiedCell" style={GetGoalieHighlightStyle(item, 12)}>{item.latestGame.shotsAgainst}</td>
                        <td className="RightJustifiedCell" style={GetGoalieHighlightStyle(item, 14)}>{(item.latestGame.savePct * 100).toFixed(1)}</td>                                        
                        <td className="RightJustifiedCell"  style={GetGoalieHighlightStyle(item, 15)}>{item.latestGame.toi}</td>                          
                    </tr>
                    ))}                                        
                </table> 
                </div>         
            }            
        </div>        
    );
}

export default LatestGameDay;