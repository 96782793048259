class LatestGoalieGameModel {    
    get date(){
        return this._date;
    }

    set date(newValue){
        this._date = newValue;
    }    

    get saves(){
        return this._saves;
    }

    set saves(newValue){
        this._saves = newValue;
    }

    get shotsAgainst(){
        return this._shotsAgainst;
    }

    set shotsAgainst(newValue){
        this._shotsAgainst = newValue;
    }

    get savePct(){
        return this._savePct;
    }

    set savePct(newValue){
        this._savePct = newValue;
    }   

    get toi(){
        return this._toi;
    }

    set toi(newValue){
        this._toi = newValue;
    }    
}

export default LatestGoalieGameModel;