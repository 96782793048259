import React from 'react';
import './Scores.css';
import SettingStorage from '../classes/SettingsStorage';
import { useTranslation } from "react-i18next";
import Converters from '../classes/Converters';
import '../index.css'
import Utilities from '../classes/Utilities';

const Scores = (props) => {    
    const { t } = useTranslation();
    let scoreFilter = SettingStorage.getStorageValue("nhlcountries_scoreFilter", "all");
    let language = SettingStorage.getLanguage();
    let showGameContent = false;
    const [scoreFilterValue, setValue] = React.useState(scoreFilter);
    let totalPoints = 0;
    let noneStyle;
    let filteredStyle;
    let allStyle;

    const updateStyles = (id) =>
    {
        noneStyle = id === "none" ? "ScoreFilterTextSelected" : "ScoreFilterText";
        filteredStyle = id === "filtered" ? "ScoreFilterTextSelected" : "ScoreFilterText";
        allStyle = id === "all" ? "ScoreFilterTextSelected" : "ScoreFilterText";
    }

    updateStyles(scoreFilter);
    
    const onScoreFilterSelected = (e) => {                
        let id = e.target.id;
        SettingStorage.setStorageValue("nhlcountries_scoreFilter", id);                
        setValue(id);       
        updateStyles(id)   
    }    

    const displayStatus = (game) => {                
        if (game.status === "1" || game.status === "2") 
        {            
            var startTime = new Date(game.startTime);   
            let currentTime = new Date();
            if (game.status === "2" && startTime < currentTime) {
                return t("scores_startsSoon");
            }
            return String(String(startTime.getHours()).padStart(2, '0') + ':' + String(startTime.getMinutes()).padStart(2, '0'));
        }
        if (game.status === "7")
        {
            if ((game.type === 3 && game.currentPeriod > 3) || game.currentPeriod === 4)
            {
                return t("gameEndingType_ot");
            }
            else if (game.type === 2 && game.currentPeriod === 5)
            {
                return t("gameEndingType_so");
            }
            return ""
        }
        if (game.status === "9")
        {            
            return t("gameStatus_ppd");        
        }
        if (game.status === "8")
        {            
            return t("gameStatus_tbd");        
        }
        return "";
    }

    const getGameTime = (game) => {        
        let rem = new Date().setHours(0, 0, 0);
        if (game.cpTimeRemaining !== "END")
        {
            const [h, m] = game.cpTimeRemaining.split(":");
            rem = new Date(new Date().setHours(0,h,m));            
        }
        
        let totalMinutes = (game.currentPeriod <= 3 || game.type === 3) ? game.currentPeriod * 20 : 65;        
        const totalTime = new Date(new Date().setHours(0, totalMinutes,0));
        const gameTime = new Date(totalTime - rem);
        return Utilities.pad((gameTime.getUTCHours() * 60 + gameTime.getUTCMinutes()), 2) + ":" + Utilities.pad(gameTime.getUTCSeconds(), 2);
    }

    const getGamePlayers = (game) => 
    {
        const homePlayers = props.players.filter(p => p.teamId === game.homeTeamId && p.isActive).sort((a, b) => (b.lastName > a.lastName) ? -1 : 1);
        const awayPlayers = props.players.filter(p => p.teamId === game.awayTeamId && p.isActive).sort((a, b) => (b.lastName > a.lastName) ? -1 : 1);

        let count = homePlayers.length;
        if (awayPlayers.length > homePlayers.length) 
        {
            count = awayPlayers.length;
        }
        let mappings = [];
        for (let i = 0; i < count; i++)
        {
            let line = { home: homePlayers[i], away: awayPlayers[i]};
            mappings.push(line);
        }
        return mappings;
    }

    const getUpcomingGamePlayerDisplayName = (player) => 
    {
        let displayName = undefined;
        if (player !== undefined)
            displayName = player.displayName + " " + "*".repeat(player.injury);
            
        return displayName;
    }

    const getSeriesStatus = (game) => 
    {            
        let status;

        if (game.seriesStatus !== undefined && game.seriesStatus !== "")
        {
            status = game.seriesStatus.replace("lead", t("scores_lead"));        
            status = status.replace("series tied", t("scores_tied"));
            status = status.replace("win", t("scores_win"));
            status = status.replace("series not started", t("scores_notStarted"));
        }        
        else
        {
            status = t("scores_notStarted");
        }

        return status;
    }
    
    const getDayDifference = (dateStringEarlier, dateStringLatest) =>
    {
        if (dateStringEarlier === undefined)
            return 0;
        let dtEarlier = new Date(dateStringEarlier);
        let dtLatest = new Date(dateStringLatest);
        return ((dtLatest.getTime() - dtEarlier.getTime())/(1000*3600*24));
    }

    let scores = props.scores.sort((a, b) => ((a.startTime > b.startTime) - (a.startTime < b.startTime)) || a.gameId > b.gameId);

    if (props.showTeamFilter) {        
        if (props.teamIds.length > 0) {
            scores = scores.filter(s => props.teamIds.includes(s.homeTeamId) || props.teamIds.includes(s.awayTeamId));
        }        
    }

    // if (scores !== undefined && scores.length > 0) {
    //     scores[0].status = "1";
    //     scores[0].recapUrl = undefined;
    //     scores[0].extUrl = undefined;        
    //     scores[1].status = "3";
    //     scores[2].status = "6";
    //     scores[2].recapUrl = undefined;
    //     scores[2].extUrl = undefined;
    //     scores[3].currentPeriod = 5;
    //     scores[4].status = "2";
    // }
    
    return (
        <div>                      
            <div className="TableContainer">
                    <table className='Table_Fill'>
                        <tr>
                            <td className='Td_Fill'>
                            <div className='Title'>{props.title}</div>
                            </td>
                            {
                                props.showAllDataSelector === "true" ? 
                                    <td className='Td_Fit'>  
                                    <div className="DivLang">       
                                        <label className={noneStyle} id="none" onClick={onScoreFilterSelected}>{t("scores_showNoScores")}</label>
                                        <label> | </label>
                                        <label className={filteredStyle} id="filtered" onClick={onScoreFilterSelected}>{t("scores_showSelected")}</label>
                                        <label> | </label>
                                        <label className={allStyle} id="all" onClick={onScoreFilterSelected}>{t("scores_showAll")}</label>
                                    </div>
                                    
                                    </td> : <div/>
                            }                            
                        </tr>
                    </table>        
                    {scores.length === 0 &&
                        <div className='NoGames'>{t("scores_noGames")}</div>
                    }              
                    {scores.map((game) =>    
                    {
                        showGameContent = false;
                        const onGameVideoClicked = (game, type) => {                            
                            if (Converters.GetPlatformType(navigator.userAgent) === "Desktop")
                            {                                
                                window.open(type === "ext" ? game.extUrl : game.recapUrl,"_self")
                            }
                            else
                            {
                                window.open(type === "ext" ? game.extUrl : game.recapUrl,"_self")
                            }
                        }
                        return (
                    <div>
                    <table className="Table_Game">                       
                    <tr key={game.id} >       
                        <td className="GameHeaderTeamCell">{game.homeTeam}</td>
                        <td className="GameHeaderDashCell">-</td>
                        <td className="GameHeaderTeamCell">{game.awayTeam}</td>                                                
                        {(Converters.GetGameStatus(game.status) > 1 && scoreFilter !== "none") &&                                                        
                        <td className="GameHeaderScoreCell">{(Converters.GetGameStatus(game.status) === 2 ? "( " : "") + game.homeTeamGoals} - {game.awayTeamGoals + (Converters.GetGameStatus(game.status) === 2 ? " )" : "")}</td>
                        }                        
                        {(Converters.GetGameStatus(game.status) > 1 && scoreFilter === "none") &&                                                        
                        <td className="GameHeaderScoreCell"></td>
                        }  
                        {
                            ((game.status === "3" || game.status === "4") && scoreFilter !== "none") && 
                            <td className="GameHeaderTimeCell">{getGameTime(game)}</td>
                        }    
                        {/* Show two empty game video cells instead of GameHeaderTimeCell to get correct amount of empty space */}
                        {
                            ((game.status === "3" || game.status === "4")) &&                             
                            <td className='GameVideoCell'><span class="icon-play2-empty"/></td>                                                       
                        }
                        {
                            ((game.status === "3" || game.status === "4")) &&                             
                            <td className='GameVideoCell'><span class="icon-play2-empty"/></td>                                                       
                        }
                        {
                            ((game.status === "5" || game.status === "6" ||game.status === "7") && scoreFilter !== "none") &&
                            <td className="GameHeaderEndStatusCell">{displayStatus(game)}</td>
                        }                       
                        {
                            ((game.status === "3" || game.status === "4" || game.status === "5" || game.status === "6" ||game.status === "7") && scoreFilter === "none") &&
                            <td className="GameHeaderEndStatusCell"></td>
                        }
                        {/* Show two empty game video cells to get correct amount of empty space */}
                        {
                            (game.status === "1" || game.status === "2" || game.status === "8" || game.status === "9") &&
                            <td className='GameVideoCell'><span class="icon-play2-empty"/></td>                                                       
                        }  
                        {
                            (game.status === "1" || game.status === "2" || game.status === "8" || game.status === "9") &&
                            <td className='GameVideoCell'><span class="icon-play2-empty"/></td>                                                       
                        }  
                        {
                            (game.status === "1" || game.status === "2" || game.status === "8" || game.status === "9") &&
                            <td className="GameHeaderStatusCell">{displayStatus(game)}</td>
                        }                                               
                        {
                            ((game.status === "6" || game.status === "7") && game.recapUrl !== undefined) &&                                                               
                                <td className='GameVideoCell' onClick={() => onGameVideoClicked(game, "recap")}><span class="icon-play2"/></td>
                        }  
                        {
                            ((game.status === "6" || game.status === "7") && game.recapUrl === undefined) &&
                            <td className='GameVideoCell'><span class="icon-play2-empty"/></td>
                        } 
                        {
                            ((game.status === "6" || game.status === "7") && game.extUrl !== undefined) &&
                                <td className='GameVideoCell' onClick={() => onGameVideoClicked(game, "ext")}><span class="icon-play2"/></td>
                        }  
                        {
                            ((game.status === "6" || game.status === "7") && game.extUrl === undefined) &&
                                <td className='GameVideoCell'><span class="icon-play2-empty"/></td>
                        }  
                    </tr>  
                    { (game.type === 3 && (scoreFilter !== "none" || game.status === "1" || game.status === "2" || game.status === "8" || game.status === "9")) &&
                        <tr>                                                      
                            <td className='PlayoffGameHeader' colSpan={3}>{t("scores_game")} {game.gameNumber}, {getSeriesStatus(game)}</td>                            
                        </tr>
                    }                                  
                    { (game.type === 3 && scoreFilter === "none" && game.status !== "1" && game.status !== "2" && game.status !== "8" && game.status !== "9") &&
                        <tr>                                                      
                            <td className='PlayoffGameHeader' colSpan={3}>{t("scores_game")} {game.gameNumber}</td>                            
                        </tr>
                    }                                  
                    </table>
                    { (game.status !== "1") &&
                    <table className="Table_Scorers">
                    {
                    game.goals.map((goal) =>                     
                    {
                        let goalType = "";
                        let strength = "";
                        if (goal.type === "tip-in")
                        {
                            goalType = t("shotType_tipIn");
                        }
                        else if (goal.type === "wrist")
                        {
                            goalType = t("shotType_wristShot");
                        }
                        else if (goal.type === "slap")
                        {
                            goalType = t("shotType_slapShot");
                        }
                        else if (goal.type === "deflected")
                        {
                            goalType = t("shotType_deflected");
                        }
                        else if (goal.type === "snap")
                        {
                            goalType = t("shotType_snapShot");
                        }
                        else if (goal.type === "backhand")
                        {
                            goalType = t("shotType_backhand");
                        }
                        else if (goal.type === "wrap-around")
                        {
                            goalType = t("shotType_wrapAround");;
                        }
                        else if (goal.type === "poke")
                        {
                            goalType = t("shotType_poke");
                        }
                        else if (goal.type === "bat")
                        {
                            goalType = t("shotType_bat");
                        }
                        else
                        {
                            goalType = goal.type;
                        }

                        if (goal.strength === "pp")
                        {
                            strength = ", " + t("goalType_pp");
                        }
                        else if (goal.strength === "sh")
                        {
                            strength = ", " + t("goalType_sh");
                        }                                               

                        if (goal.isEmptyNet)
                        {
                            strength = strength + ', ' + t("goalType_en");;
                        }

                        const [minutes, seconds] = goal.time.split(':');                        
                        let time = String((goal.period - 1) * 20 + parseInt(minutes)).padStart(2, '0') + ':' + seconds;

                        if (goal.ordinalNum === "SO")
                        {
                            time = "65:00";
                        }

                        let scorer = (goal.scorerName.fi !== undefined && language === "fi") ?  goal.scorerName.fi : goal.scorerName.default;
                        let goalTypeDisplayString = goalType !== undefined ? '(' + goalType + strength + ')' : "";
                        let assistOne = (goal.assistOneName?.fi !== undefined && language === "fi") ?  goal.assistOneName.fi : goal.assistOneName?.default;
                        let assistTwo = (goal.assistTwoName?.fi !== undefined && language === "fi") ?  goal.assistTwoName.fi : goal.assistTwoName?.default;
                        
                        if (assistTwo !== undefined)
                        {
                            assistOne = assistOne + ', ';
                        }

                        
                        let scorerStyle = "Player_Normal";
                        let assistOneStyle = "Assist_One";
                        let assistTwoStyle = "Assist_Two";
                        let isHighlightedScorer = false;
                        if (props.players.find(player => goal.scorerId === player.playerId) !== undefined)
                        {
                             scorerStyle = "Player_Highlight";
                             isHighlightedScorer = true;

                             if (goal.time !== "00:00")
                             {
                                totalPoints++;
                             }                             
                        }
                        if (props.players.find(player => goal.assistOneId === player.playerId) !== undefined)
                        {
                             assistOneStyle = "Assist_One_Highlight";
                             isHighlightedScorer = true;

                             if (goal.time !== "00:00")
                             {
                                totalPoints++;
                             }
                        }
                        if (props.players.find(player => goal.assistTwoId === player.playerId) !== undefined)
                        {
                             assistTwoStyle = "Assist_Two_Highlight";
                             isHighlightedScorer = true;
                             
                             if (goal.time !== "00:00")
                             {
                                totalPoints++;
                             }
                        }

                        let goals = goal.homeGoals + '-' + goal.awayGoals;
                        if (goal.ordinalNum === "SO"){
                            goals = t("gameEndingType_so");
                        }
                        showGameContent |= scoreFilter === "all" || (scoreFilter === "filtered" && isHighlightedScorer);
                        
                        const onVideoClicked = (g) => {                            
                            if (Converters.GetPlatformType(navigator.userAgent) === "Desktop")
                            {
                                window.open(g.videoUrl,"_self")
                            }
                            else
                            {
                                window.open(g.videoUrl,"_self")
                            }
                        }

                        if (scoreFilter === "all" || (scoreFilter === "filtered" && isHighlightedScorer))
                        {
                            return (                    
                                <div>
                                <table className="Table_Goal">
                                    <tr className="ScorerTableRow" key={goal.id} >                               
                                        <td className="LeftJustifiedCell">{time}</td>                        
                                        <td className="LeftJustifiedCell">{goals}</td> 
                                        <td className={scorerStyle}>{scorer}</td>                                                                
                                        <td className="GoalType" >{goalTypeDisplayString}</td> 
                                        {              
                                            goal.videoUrl !== undefined &&                              
                                            <td className='GameVideoCell' rowSpan="2" onClick={() => onVideoClicked(goal)}><span class="icon-play2"/></td>                        
                                        }                                        
                                    </tr>                    
                                    {
                                        assistOne !== undefined && 
                                        <tr className="Table_Assists" key={goal.id} >                               
                                            <td className="LeftJustifiedCell"></td>
                                            <td className="LeftJustifiedCell"></td>
                                            <td colSpan={2} className="LeftJustifiedCell"><div className="Assist_Main">(<div className={assistOneStyle}>{assistOne}</div>{assistTwo !== undefined && <div className={assistTwoStyle}>{assistTwo}</div>})</div></td>                                                            
                                        </tr>                    
                                    }                                
                                </table>
                            
                        </div>
                        )
                        }                        
                    })
                    }
                    { 
                    (game.status !== "1") &&                   
                    <div className='Div_FullWidth'>
                        <table className="Table_Goalies">
                    {   
                        game.goalies.filter(g => g.timeOnIce !== "00:00").sort((a, b) => a.homeTeam).map((goalie) =>                        
                        {
                            let style = "Player_Normal";
                            let styleRj = "Player_NormalRightJustified";
                            let isHighlightedGoalie = false;
                            if (props.players.find(player => goalie.playerId === player.playerId) !== undefined)
                            {
                                style = "Player_Highlight";
                                styleRj = "Player_HighlightRightJustified";
                                isHighlightedGoalie = true;
                            }                            
                            
                            showGameContent |= scoreFilter === "all" || (scoreFilter === "filtered" && isHighlightedGoalie);
                            if (scoreFilter === "all" || (scoreFilter === "filtered" && isHighlightedGoalie))
                            {
                                return (                                    
                                <tr>
                                    <td className="InvisibleText">00:00</td>
                                    <td className="InvisibleText">0-0</td>
                                    <td className={style}>{goalie.team}</td>
                                    <td className={style}>{goalie.displayName}</td>
                                    <td className={styleRj}>{`${goalie.saves}/${goalie.shotsAgainst}`}</td>
                                    <td className={styleRj}>{`${(goalie.savePct * 100).toFixed(1)}%`}</td>
                                    <td className={style}>{goalie.timeOnIce}</td>
                                </tr>
                                )
                            }     
                        })                        
                    }                    
                    </table>
                    </div>                    
                    }
                    { showGameContent === 1 &&
                        <table className='Table_Empty'/>
                    }
                    </table>                    
                    }                                        
                    { (game.status === "1" || game.status === "2" || game.status === "8") && getGamePlayers(game).length > 0 &&                        
                        <table className="Table_PregamePlayers">
                        {                       
                            getGamePlayers(game).map((pair) => {                                                                
                                //let homePlayerStyle = pair.home === undefined || pair.home.injury > 0 || (pair.home.games === 0 && game.type !== "PR") || getDayDifference(pair.home.latestGame?.date, game.date) > 35 ? "PregamePlayerCellOpaque" : "PregamePlayerCell";
                                //let awayPlayerStyle = pair.away === undefined || pair.away.injury > 0 || (pair.away.games === 0 && game.type !== "PR") || getDayDifference(pair.away.latestGame?.date, game.date) > 35 ? "PregamePlayerCellOpaque" : "PregamePlayerCell";
                                let homePlayerStyle = pair.home === undefined || pair.home.injury > 0 || getDayDifference(pair.home.latestGame?.date, game.date) > 35 ? "PregamePlayerCellOpaque" : "PregamePlayerCell";
                                let awayPlayerStyle = pair.away === undefined || pair.away.injury > 0 || getDayDifference(pair.away.latestGame?.date, game.date) > 35 ? "PregamePlayerCellOpaque" : "PregamePlayerCell";
                                return (                                    
                                    <tr>
                                        <td className={homePlayerStyle}>{getUpcomingGamePlayerDisplayName(pair.home)}</td>
                                        <td className="GameHeaderDashCell" style={{ color: "black"}}>-</td>
                                        <td className={awayPlayerStyle}>{getUpcomingGamePlayerDisplayName(pair.away)}</td>   
                                        <td className="GameHeaderScoreCell"></td>                                          
                                        <td className="GameHeaderEndStatusCell"></td>                                            
                                        <td className='GameVideoCell'><span class="icon-play2-empty"/></td>
                                        <td className='GameVideoCell'><span class="icon-play2-empty"/></td>
                                    </tr>
                                    )
                            })
                        }
                        </table>
                    }                    
                    { (game.status === "1" || game.status === "2" || game.status === "8") && getGamePlayers(game).length === 0 &&                        
                        <table className="Table_PregamePlayersEmpty"/>                                                
                    }
                    </div>                    
                )})}
                {         
                (props.scores.find(g => g.status !== "1" && g.status !== "2" && g.status !== "8" && g.status !== "9" && scoreFilter !== "none") !== undefined &&
                <div className='Div_TopMargin'>
                    {t("latestGameDay_totalPoints")}: {totalPoints}
                </div>)                
                }                            
            </div>              
        </div>        
    );
}

export default Scores;