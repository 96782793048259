import './News.css';
import DataFetcher from '../classes/DataFetcher';
import { useTranslation } from "react-i18next";
import React, { useEffect } from 'react';
import Digital from 'react-activity/dist/Digital';
import "react-activity/dist/Digital.css";
import Utilities from "../classes/Utilities";

const News = (props) => {        
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = React.useState(false);    
    const [playerNews, setPlayerNews] = React.useState([]);        

    const loadNews = async () => {        
        let pn = [];        
        try
        {
            setIsLoading(true);                   
            
            let players = props.players.filter(p => p.injury > 0 && p.espnId !== undefined).slice(0, 20);
            for (const p of players)
            {
                let news = await DataFetcher.fetchPlayerNews(p.espnId);      

                if (news !== undefined)
                {
                    news["name"] = p.displayName;
                    pn.push(news);                                
                }                
            }

            pn.sort(function(a,b){                
                return b.lastModified - a.lastModified;
              });
        }
        finally
        {
            setPlayerNews(pn);
            setIsLoading(false);
        }                    
      };

    useEffect(() => {
        loadNews();
      }, []);

    const getDate = (dt) =>
    {
        let time = dt;
        time.setMinutes(time.getMinutes() - time.getTimezoneOffset());            
        let timeString = String(String(time.getUTCHours()).padStart(2, '0') + '.' + String(time.getUTCMinutes()).padStart(2, '0')) + "." + String(time.getUTCSeconds()).padStart(2, '0');
        return Utilities.getWeekday(t, dt) + " " + dt.toLocaleDateString() + " " + timeString;
    }


    const loadingContent = <div className="Progress"><h4>{t("home_updatingData")}</h4><Digital/><div className='ProgressSmallPrint'>{t("home_updatingDataNote")}</div></div>;

    return ( 
        isLoading ? loadingContent :       
        <div style={{ width: `100%;`, maxWidth: `500px;`}}>                        
            <div className="TableContainer">
                {playerNews.map((news) => (
                    <div className='NewsItem'>                        
                        <h5>
                            <div>{getDate(news.lastModified)}</div>
                            <div>{news.name}</div>                             
                        </h5>                        
                        <p>{news.headline}</p>
                        <p>{news.story}</p>                                                                        
                    </div>                    
                ))} 
            </div>              
        </div>        
    );
}

export default News;