class TeamStandingModel {     
    get name(){
        return this._name;
    }

    set name(newValue){
        this._name = newValue;
    }

    get abbrev(){
        return this._abbrev;
    }

    set abbrev(newValue){
        this._abbrev = newValue;
    }
    
    get id(){
        return this._id;
    }

    set id(newValue){
        this._id = newValue;
    }
    
    get wins(){
        return this._wins;
    }

    set wins(newValue){
        this._wins = newValue;
    }

    get losses(){
        return this._losses;
    }

    set losses(newValue){
        this._losses = newValue;
    }

    get ot(){
        return this._ot;
    }

    set ot(newValue){
        this._ot = newValue;
    }

    get rw(){
        return this._rw;
    }

    set rw(newValue){
        this._rw = newValue;
    }

    get row(){
        return this._row;
    }

    set row(newValue){
        this._row = newValue;
    }

    get games(){
        return this._games;
    }

    set games(newValue){
        this._games = newValue;
    }

    get points(){
        return this._points;
    }

    set points(newValue){
        this._points = newValue;
    }

    get clinch(){
        return this._clinch;
    }

    set clinch(newValue){
        this._clinch = newValue;
    }

    get divPos(){
        return this._divPos;
    }

    set divPos(newValue){
        this._divPos = newValue;
    }

    get confPos(){
        return this._confPos;
    }

    set confPos(newValue){
        this._confPos = newValue;
    }

    get wcPos(){
        return this._wcPos;
    }

    set wcPos(newValue){
        this._wcPos = newValue;
    }

    get divAbbrev(){
        return this._divAbbrev;
    }

    set divAbbrev(newValue){
        this._divAbbrev = newValue;
    }
    
    get confAbbrev(){
        return this._confAbbrev;
    }

    set confAbbrev(newValue){
        this._confAbbrev = newValue;
    }
}

export default TeamStandingModel;